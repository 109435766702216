import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  buttons?: string[]
  image?: ImageProps
  title?: string
}

export const Media = memo(function Media({ buttons, image, title }: Props) {
  if (!buttons) {
    return null
  }

  return (
    <Container>
      <Background>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </Background>

      {title ? <Title>{title}</Title> : null}

      {buttons.map((item: any, index) => (
        <CTA
          key={index}
          icon="download"
          label={item.label}
          target="_blank"
          URL={item.url}
          variant="outline"
        />
      ))}
    </Container>
  )
})

const Container = styled.div`
  width: calc(50% - 8.333vw);
  margin: 5rem 8.333vw 0 0;

  @media (max-width: 767px) {
    width: 100%;
    margin: 5rem 0 0;
  }
`

const Background = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin-bottom: 1.875rem;
  padding-bottom: 70%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 2.75rem;
    padding-bottom: 50%;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  line-height: 1.375rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;

  @media (max-width: 767px) {
    margin-bottom: 1.6875rem;
    padding: 0 1.875rem;
  }
`

const CTA = styled(Button)`
  width: 100%;
  justify-content: flex-start;
  margin-top: 0.375rem;

  @media (max-width: 767px) {
    width: calc(100% - 3.75rem);
  }
`
