import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Media, Props as MediaProps } from './Media'

export interface Props {
  medias: MediaProps[]
  siteName?: string
  title?: string
}

export const Medias = memo(function Medias({ medias, siteName, title }: Props) {
  if (!medias) {
    return null
  }

  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      {siteName ? <SiteName>{siteName}</SiteName> : null}

      <List row wrap>
        {medias.map((item, index) => (
          <Media key={index} {...item} />
        ))}
      </List>
    </Container>
  )
})

const Container = styled.section`
  margin: 15.25rem auto 12.5rem;
  padding: 0 16.667vw;
  text-align: center;

  @media (max-width: 767px) {
    padding: 0;
    margin: 8.6875rem auto 6.25rem;
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.75rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
`
const SiteName = styled.h2`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.75rem;
  text-transform: uppercase;

  @media (min-width: 1024px) {
    display: none;
  }

  @media (max-width: 1023px) {
    margin-top: 0.25rem;
    font-size: 1.5rem;
    line-height: 1.875rem;
  }
`

const List = styled(FlexBox)`
  margin-right: -8.333vw;

  @media (max-width: 767px) {
    margin-right: 0;
  }
`
